/* eslint-disable react/button-has-type */
import { Button, Grid, IconButton, Theme, useMediaQuery } from '@material-ui/core';
import { take } from 'lodash';
import { useState } from 'react';
import { ReactComponent as Arrow } from '../../../../../Asset/svg/arrow_right.svg';
import { ReactComponent as Check } from '../../../../../Asset/svg/check.svg';
import { ReactComponent as ZoomIn } from '../../../../../Asset/svg/zoom_in.svg';
import ItemChoice from '../../ItemChoice';
import ItemChoiceSquare from '../../ItemChoiceSquare';
import ModalZoomImg from '../../ModelZoomImg';
import style from '../style';

const Model6 = (props: any) => {
  const { page, handleChooseDestion, endGame, handleChooseVariable, endGameVariable } = props;
  const classes = style();
  const [selectedChoice, setSelectedChoice] = useState<any>({});
  const [open, setOpen] = useState<boolean>(false);

  const chooseChoice = (elemToChoose: any) => {
    setSelectedChoice(elemToChoose);
    if (page.typeChoice === 'redirection') {
      window.open(elemToChoose.destinationUrl, '_blank');
    }
  };

  const isSelectedData = (elem: any) => {
    return elem.id === selectedChoice.id;
  };

  const goToDestination = () => {
    if (handleChooseDestion && selectedChoice.id) {
      handleChooseDestion(selectedChoice.destination);
      if (selectedChoice.selectedVariable && handleChooseVariable) {
        handleChooseVariable(selectedChoice.selectedVariable);
      }
    }
  };
  const goToDestinationDirect = (idDestination: any, idVariableSelected: any) => {
    if (handleChooseDestion) {
      handleChooseDestion(idDestination);
      if (handleChooseVariable) {
        handleChooseVariable(idVariableSelected);
      }
    }
  };
  const isXs = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

  return (
    <div className={classes.container}>
      <Grid className={classes.containerGrid} container={true} style={{ height: '100%' }}>
        <Grid item={true} sm={12} className={classes.blockContentParent}>
          <div
            className={classes.blockContentImg}
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
            }}>
            <IconButton
              color="secondary"
              aria-label="delete"
              style={{ background: '#000A8C' }}
              className={classes.btnFloat}
              onClick={() => setOpen(true)}>
              <ZoomIn className={classes.icon} />
            </IconButton>
            <div className={classes.imgContainer}>
              <img className={classes.img} src={page?.illustration?.url} alt="page covurture" />
            </div>
            <ModalZoomImg
              open={open}
              fileUrl={page?.illustration?.url}
              handleClose={() => setOpen(false)}
            />
          </div>
        </Grid>
        <Grid item={true} sm={12} className={classes.blockContentParent}>
          <div
            className={classes.blockContent}
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              justifyContent: 'center',
            }}>
            {/* Block choice */}
            {page?.choiceTitle && page?.choiceTitle !== '' && (
              <div className={classes.choiceTitle}>{page?.choiceTitle}</div>
            )}
            {page?.typeChoice !== 'end_game' &&
              page?.typeChoice !== 'pursue' &&
              page?.choiceDisplay === 'horizontal' &&
              page?.choice?.map((elem: any, index: number) => (
                <div
                  style={{ marginBottom: index >= 1 ? 20 : 10 }}
                  onClick={() => chooseChoice(elem)}>
                  <ItemChoice
                    id={elem.id}
                    number={index + 1}
                    isSelected={isSelectedData(elem)}
                    title={elem.text}
                  />
                </div>
              ))}
            {page?.typeChoice !== 'end_game' &&
              page?.typeChoice !== 'pursue' &&
              page?.choiceDisplay === 'carre' && (
                <Grid container={true} justifyContent="center" spacing={isXs ? 1 : 4}>
                  {take(page.choice, 4).map((elem: any, index: number) => (
                    <Grid item={true} sm="auto" onClick={() => chooseChoice(elem)}>
                      <ItemChoiceSquare
                        isSelected={isSelectedData(elem)}
                        title={elem.name || elem.text}
                      />
                    </Grid>
                  ))}
                </Grid>
              )}
            {page?.typeChoice === 'multiples' && (
              <div
                className={classes.btnValider}
                style={page.choiceDisplay === 'carre' ? { justifyContent: 'center' } : {}}>
                <Button
                  size="small"
                  color="primary"
                  disableRipple={true}
                  className={classes.btn}
                  style={{ background: '#000A8C' }}
                  onClick={goToDestination}
                  endIcon={<Check className={classes.btnicon} />}>
                  Valider mon choix
                </Button>
              </div>
            )}
            {page?.typeChoice === 'end_game' && (
              <div
                className={classes.btnEnd}
                style={page.choiceDisplay === 'carre' ? { justifyContent: 'center' } : {}}>
                <div className={classes.choiceEndGame}>
                  Ceci est la fin du jeu. <br /> Merci.
                </div>
                <Button
                  size="small"
                  color="primary"
                  disableRipple={true}
                  className={classes.btn}
                  style={{ background: '#000A8C', height: 'auto' }}
                  onClick={endGame}
                  endIcon={<Arrow className={classes.btnicon} />}>
                  {page.textEndGame}
                </Button>
              </div>
            )}
            {page?.typeChoice === 'pursue' && (
              <div className={classes.btnPursue}>
                <Button
                  size="small"
                  color="primary"
                  disableRipple={true}
                  className={classes.btnPursueInside}
                  style={{ background: '#000A8C' }}
                  onClick={() =>
                    goToDestinationDirect(
                      page?.choice[0]?.destination,
                      page?.choice[0]?.selectedVariable
                    )
                  }
                  endIcon={<Arrow className={classes.btnicon} />}>
                  <span dangerouslySetInnerHTML={{ __html: page?.choice[0].text }} />
                </Button>
              </div>
            )}
            {page?.isVariable && (
              <div className={classes.btnPursue}>
                <Button
                  size="small"
                  color="primary"
                  disableRipple={true}
                  className={classes.btnPursueInside}
                  style={{ background: '#000A8C' }}
                  onClick={endGameVariable}
                  endIcon={<Arrow className={classes.btnicon} />}>
                  Retour à la liste des ressources
                </Button>
              </div>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Model6;
