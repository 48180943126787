import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { BorderAll } from '@material-ui/icons';
import { publicSvgPath } from '@orientaction/utils';

export default makeStyles((theme: Theme) => ({
  btnPrimary: {
    background: '#000A8C',
    textTransform: 'none',
  },
  separator: {
    margin: '20px 0px',
    width: '100%',
    position: 'relative',
    height: '2px',
    background: '#E4E4F2',
    display: 'block',
  },
  formInput: {
    width: '100%',
    '& div fieldset': {
      borderColor: '#E4E4F2',
      top: '-6px',
    },
    '& div input': {
      paddingTop: 12,
      paddingBottom: 12,
      fontSize: 14,
    },
  },
  label: {
    fontSize: 14,
    marginBottom: 10,
  },
  card: {
    padding: '20px 30px',
    background: 'white',
    borderLeft: '10px solid #E6E6E6',
    borderRadius: 5,
  },
  closeBtn: {
    fontSize: 26,
    margin: 8,
    border: '1px solid #E4E4F2',
    borderRadius: '50%',
    width: 36,
    height: 36,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 2,
    cursor: 'pointer',
  },
  section1: {
    background: '#F1F1F1',
    padding: '15px 15px 4px',
    borderRight: '1px solid #E4E4F2',
    borderBottom: '1px solid #E4E4F2',
    height: '100%',
  },
  inner: {
    display: 'flex',
    justifyContent: 'space-around',
    paddingBottom: '4px',
  },
  section2: {
    height: 'auto',
    borderBottom: '1px solid #E4E4F2',
    background: '#F1F1F1',
    display: 'flex',
    alignItems: 'center',
  },
  section3: {
    minHeight: '90vh',
    borderRight: '1px solid #E4E4F2',
    background: '#F1F1F1',
    maxHeight: '90vh',
    height: '90vh',
  },
  section4: {
    background: '#F7F7FB',
    minHeight: '90vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 50,
  },
  icon: {
    width: 15,
    height: 15,
  },
  title2: {
    fontFamily: 'ITC Avant Garde Gothic Std Demi',
    fontSize: '14px',
    fontWeight: 600,
    textTransform: 'none',
  },
  wrapbutton: {
    position: 'relative',
    paddingBottom: '4px',
    '&:hover $tooltipLabel': {
      visibility: 'visible',
      opacity: 1,
    },

    '&.active $iconButton': {
      background: 'none',
      border: '1px solid #E63C3C',
      padding: '0',
      '& svg': {
        '& path': {
          fill: '#E63C3C',
        },
      },
    },

    '&:first-child': {
      '& $tooltipLabel': {
        width: '140px',
        left: '-30px',
        transform: 'none',
      },
    },

    '&:nth-of-type(2)': {
      '& $tooltipLabel': {
        width: '165px',
      },
    },
  },
  tooltipLabel: {
    position: 'absolute',
    top: '100%',
    left: '50%',
    transform: 'translateX(-50%)',
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 800,
    backgroundColor: '#E63C3C',
    color: 'white',
    padding: '10px 12px 9px',
    borderRadius: theme.shape.borderRadius,
    visibility: 'hidden',
    opacity: 0,
    transition: 'visibility 0s, opacity 0.5s linear',
    zIndex: 1,
    cursor: 'pointer',
  },
  iconButton: {
    position: 'relative',
    width: '39px',
    height: '39px',
    borderRadius: '5px',
    '&:hover': {
      background: 'none',
      border: '1px solid #E63C3C',
      padding: '0',
      '& svg': {
        '& path': {
          fill: '#E63C3C',
        },
      },
    },
  },
  iconS: {
    fontSize: 17,
  },
  containerIconS: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  containerPageNumber: {
    display: 'flex',
    alignItems: 'center',
  },
  formInputNumber: {
    background: 'transparent',
    '& div fieldset': {
      borderColor: 'transparent',
      top: '-6px',
    },
    '& div input': {
      paddingTop: 12,
      paddingBottom: 12,
      fontSize: 14,
      textAlign: 'center',
      width: 22,
    },
    '& .MuiOutlinedInput-root': {
      background: 'transparent',
    },
  },
  containerGrid: {
    display: 'flex',
    alignItems: 'center',
  },
  iconbig: {
    width: 19,
    height: 19,
  },
  btnFull: {
    borderRadius: 5,
    color: '#000A8C',
    width: '188px',
    padding: '15px 25px',
    textTransform: 'none',
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
    },
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-end',
    },
  },
  container: {
    padding: '1px',
    marginBottom: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px dashed #E4E4F2',
    cursor: 'pointer',
  },

  textFooter: {
    color: '#B0B5FF',
    fontFamily: 'Open Sans',
    fontSize: '10px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '14px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  btnOutFilled: {
    borderRadius: 5,
    color: 'white',
    width: '100%',
    border: '1px solid white',
    textTransform: 'none',
  },
  title: {
    color: '#000A8C',
    textAlign: 'center',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 700,
    textTransform: 'none',
    lineHeight: 1,
    marginBottom: 15,
    [theme.breakpoints.down('xs')]: {
      marginTop: 12,
      fontSize: '18px',
      padding: '0px 10px',
    },
  },
  titleStatus: {
    color: '#FFF',
    textAlign: 'center',
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontStyle: 'italic',
    fontWeight: 600,
    lineHeight: '14px',
    margin: '5px 0px',
  },
  containerCover: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#FFF',
    borderRadius: 5,
    borderLeft: '12px solid #757788',
    padding: '141% 0px 0px',
    position: 'relative',
    marginTop: 20,
    [theme.breakpoints.down('xs')]: {
      marginTop: 40,
    },
  },
  content: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  containerElement: {
    height: '80%',
    width: '80%',
    borderRadius: 5,
    overflow: 'hidden',
    [theme.breakpoints.down('xs')]: {
      marginBottom: 15,
    },
  },
  spanText: {
    color: '#FFF',
    textAlign: 'right',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '14px',
  },
  img: {
    objectFit: 'contain',
    width: '100%',
    height: '100%',
  },
  btn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    color: 'white',
    textTransform: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    position: 'absolute',
    top: '20px',
    left: '40px',
    [theme.breakpoints.down('xs')]: {
      top: '25px',
      left: 'auto',
    },
  },
  btn2: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    color: 'white',
    textTransform: 'none',
    backgroundColor: 'transparent',
    position: 'relative',
    margin: '30px auto 25px',
    maxWidth: '1280px',
    flex: 1,
  },
  containerDiv: {
    position: 'relative',
    padding: 20,
    margin: '20px 0px 40px',
  },
  containerModel: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0px 0px 50px',
    padding: '0px 15px',
  },
  colorPrimary: {
    backgroundColor: '#000A8C',
    height: '15px',
    borderRadius: '60px',
    [theme.breakpoints.down('xs')]: {
      height: '8px',
    },
  },
  barColorPrimary: {
    background:
      'transparent linear-gradient(230deg, #FFDC27 0%, #E63C3C 50%, #000A8C 100%) 0% 0% no-repeat padding-box',
    height: '15px',
    borderRadius: '60px',
    [theme.breakpoints.down('xs')]: {
      height: '8px',
    },
  },
  progressBar: {
    width: '50%',
    margin: '30px auto auto auto',
    maxWidth: '1280px',
    [theme.breakpoints.down('xs')]: {
      margin: 'auto auto 20px auto',
      width: '60%',
    },
  },
  btnHeader: {
    display: 'flex',
  },
  btnTab: {
    borderRadius: 0,
    border: '1px solid #E4E4F2',
    textTransform: 'none',
    paddingLeft: 20,
    paddingRight: 20,
  },
  separatorGrid: {
    borderBottom: '1px solid #E4E4F2',
  },
  select: {
    fontFamily: 'Poppins',
    fontSize: 12,
    fontWeight: 500,
    width: '40%',
  },
  blockSelectType: {
    margin: '10px 0px 20px',
  },
  blockBtnSelect:{
    display: 'flex',
    justifyContent: 'space-between',
  },
  drawerContent: {
    width: '50%',
    borderTop: '2px solid #23296F',
    borderRadius: 16,
  },
  closeBtnDrawer: {
    position: 'absolute',
    width: 50,
    height: 50,
    right: '0.001rem',
    top: '0.001rem',
    color: '#fff',
  },
  contentChild: {
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 30,
    paddingBottom: 30,
  },
  titleDrawer: {
    lineHeight: '30px',
    color: '#fff',
    textTransform: 'none',
    marginBottom: 20,
    fontFamily: 'Poppins',
    fontSize: 18,
    fontWeight: 700,
  },
  rootStyle: {
    background: '#000a8c6b',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.light,
    borderBottom: '2px solid #23296F',
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 16,
  },
  titleContainer: {
    marginTop: 25,
  },
  variableFormTitle: {
    fontFamily: 'Poppins',
    fontSize: 22,
    fontStyle: 'normal',
    fontWeight: 700,
    color: theme.palette.primary.light,
    textAlign: 'center',
  },
  error: {
    '& div fieldset': {
      borderColor: `${theme.palette.error.main} !important`,
    },
  },
  marginTop: {
    marginTop: 15,
  },
  textHeader: {
    display: 'none',
    color: 'white',
    marginLeft: 0,
    '@media(min-width: 600px)': {
      display: 'block',
    },
  },
  circle: {
    position: 'relative',
    width: '3vw',
    height: '3vw',
    borderRadius: '50%',
    background: '#000A8C',
  },
  number: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: '#fff',
    fontSize: 12,
  },
  summaryText: {
    width: '70%',
  },
  summaryTextH6: {
    fontSize: 14,
    fontFamily: 'Poppins',
    fontWeight: 700,
    color: '#0E1247',
  },
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: 10,
    background: 'white',
    borderRadius: 5,
  },
  iconButtonV: {
    padding: 5,
  },
  accordionDetails: {
    color: theme.palette.primary.dark,
  },
  variableNameLabel: {
    color: theme.palette.primary.dark,
    fontFamily: 'Poppins',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 700,
    paddingLeft: 10,
  },
  variableNameText: {
    color: theme.palette.success.dark,
    fontFamily: 'Poppins',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 700,
    textTransform: 'uppercase',
    paddingLeft: 3,
  },
  accordionSummary: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
  },
  blockItemsVariable: {
    marginTop: 20,
  },
  btnPrimarySticky: {
    textTransform: 'none',
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
    width: '15vw',
    background: '#000A8C',
  },
  button: {
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    paddingLeft: 20,
    paddingRight: 20,
    textTransform: 'none',
    marginRight: 15,
    width: '12vw',
    '@media only screen and (max-width: 600px)': {
      display: 'none',
    },
    '@media only screen and (max-width: 391px)': {
      display: 'none',
    },
  },
  btnText: {
    fontSize: '0.8rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '0.7rem',
    },
  },
  footerWrapper: {
    position: 'sticky',
    bottom: 0,
    width: '100%',
    backgroundColor: '#f0f0f0',
    display: 'flex',
    justifyContent: 'center',
    padding: '10px 0 10px 0',
    marginRight: 50,
    borderTop: '1px solid var(--neutres-gris-label, #9799B1)',
    background: '#EDEEF2',
  },
  textEmptyVariable: {
    textAlign: 'center',
    display: 'flex',
    height: 200,
    justifyContent: 'center',
    alignItems: 'center',
    '& h5': {
      fontFamily: 'Poppins',
      fontSize: 18,
      color: 'black',
    },
  },
  containerModel1: {
    background: '#FAFAFA',
    borderRadius: 5,
    width: '100%',
    height: '80vh',
    maxWidth: 1280,
    margin: 'auto',
  },
  btnicon: {
    width: 13,
    height: 'auto',
  },
  blockContentParent: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      borderRight: 'none !important',
      borderBottom: '1px solid rgb(225, 225, 225)',
      width: '100%',
    },
  },
  grayBGContent: {
    flex: 1,
    overflow: 'hidden',
    '&>div': {
      color: theme.palette.primary.main,
      overflow: 'auto',
    },
    background: `url(${publicSvgPath}/fond_homepage.png), #0E1444`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% auto',
    backgroundPosition: '0% 0%',
    '@media only screen and (max-width: 600px)': {
      background: `url(${publicSvgPath}/fond_transparent_mobile_accueil.png), #0E1444`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100% auto',
      backgroundPosition: '0% 0%',
    },
    '@media only screen and (max-width: 361px)': {
      background: `url(${publicSvgPath}/fond_transparent_mobile_accueil.png), #0E1444`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100% auto',
      backgroundPosition: '0% 0%',
    },
  },
  btnCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    color: 'white',
    textTransform: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      top: '25px',
      left: 'auto',
    },
  },
  iconBoutton: {
    position: 'absolute',
    top: '5px',
    right: '5px',
  },
  text: {
    color: 'black',
    fontSize: 12,
    position: 'absolute',
    right: 100,
    top: '50%',
    transform: 'translateY(-50%)',
    fontStyle: 'italic',
  },
}));
